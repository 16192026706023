<template>
  <div class="production-item">
    <div class="production-img">
      <img :src="info.img" :alt="info.cDrugName" />
    </div>
    <div class="production-slide">
      <p class="production-title">
        <span class="status">NEW</span>
        <span class="production-name">{{ info.cDrugName }}</span>
      </p>
    </div>
  </div>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2021-11-25 08:26:46
 *@version: V1.0.0
 *@description: 药品样式
 *************************************************************************/
export default {
  name: "ProductionItem",
  components: {},
  props: {
    info: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },

  computed: {},
  watch: {},

  methods: {},
};
</script>
<style lang="less" scoped>
.production-item {
  position: relative;
  text-align: center;
  font-size: 0;
  overflow: hidden;
  &:hover {
    .production-img {
      img {
        transform: scale(1.1);
        transition: 0.5s;
      }
    }
    .production-slide {
      bottom: 2rem;
      transition: 0.5s;
    }
  }
}
.production-img {
  img {
    width: 100%;
    height: 100%;
  }
}
.production-slide {
  position: absolute;
  bottom: 1.5rem;
  width: 242px;
  background: rgba(0, 0, 0, 0.3);
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.5s ease-in-out;
  height: 60px;
  overflow: hidden;
  &:hover{
    .production-title{
      background-color: #022a75;
    }
  }
}
.production-title {
  padding-left: 1rem;
  line-height: 63px;
  text-align: left;
  transition: all .3s ease-in-out;
  span {
    display: inline-block;
    color: #fff;
    font-size: 12px;
    vertical-align: middle;
    line-height: 1;
  }
  .status {
    padding: 0 2px;
    margin-right: 5px;
    border: 1px solid #fff;
    text-align: center;
    font-family: "Times New Roman";
  }
}
</style>
