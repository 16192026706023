<template>
  <div class="page-section">
    <div class="container">
      <a-row type="flex" class="adv-row">
        <a-col
          :xs="12"
          :sm="12"
          :md="6"
          :lg="6"
          :xl="6"
          v-for="(item, i) in advantageList"
          :key="i"
          class="adv-item"
        >
          <h5 class="adv-title">{{ item.title }}</h5>
          <p class="adv-description">{{ item.desc }}</p>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2021-11-24 16:56:47
 *@version: V1.0.0
 *@description: 企业优势
 *************************************************************************/
export default {
  name: "CompanyAdvantage",
  components: {},
  props: {},
  data() {
    return {
      advantageList: [
        {
          title: "服务宗旨",
          desc: "全心全意为客户创造价值",
        },
        {
          title: "服务理念",
          desc: "客户的满意就是我们不懈动力",
        },
        {
          title: "服务定制",
          desc: "因为专业 所以值得信赖",
        },
        {
          title: "服务目标",
          desc: "注重提高产品质量 注重建立品牌",
        },
      ],
    };
  },

  computed: {},
  watch: {},

  methods: {},
};
</script>
<style lang="less" scoped>
.page-section {
  background-color: #022a75;
}
.adv-row {
  justify-content: space-between;
}
.adv-item {
  padding: 0.75rem;
  visibility: visible;
  animation-duration: 2s;
  animation-delay: 0.6s;
  animation-iteration-count: 1;
  animation-name: bounceIn;
}
.adv-title {
  font-size: 24px;
  text-align: center;
  line-height: 1.5;
  color: #fff;
  font-family: Microsoft YaHei;
}
.adv-description {
  font-size: 16px;
  text-align: center;
  color: #fff;
  font-family: Microsoft YaHei;
  font-weight: normal;
}
@media (max-width: 768px) {
  .adv-title,
  .adv-description {
    font-size: 12px;
  }
}
</style>
