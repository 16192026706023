<template>
  <section class="page-section">
    <div class="container">
      <div class="content">
        <h3 class="page-title">公司简介</h3>
        <p>
          北京英芙麦迪科技有限公司成立于2006年，公司尊崇“踏实、拼搏、责任”的企业精神，并以诚信、共赢、开创经营理念，创造良好的企业环境，以全新的管理模式，完善的技术，周到的服务，卓越的品质为生存根本，我们始终坚持用户至上
          用心服务于客户，坚持用自己的服务去打动客户。
        </p>
        <p>
          我们与韩国韩美药品株式会社、北京韩美药品有限公司达成紧密战略合作，是韩美集团营销业务的重要成员。公司具有雄厚的经济实力，独特的市场运作能力，长远的市场规划，独立的市场营销
          网络，良好的商业信誉及市场的丰富经验。公司注重人性化管理，以人
          为本，并以高效、分享、友爱、热情为团队管理目标。
        </p>
      </div>
    </div>
  </section>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2021-11-24 14:09:19
 *@version: V1.0.0
 *@description: 公司简介描述内容区域
 *************************************************************************/
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>
<style lang="less" scoped>
p {
  text-indent: 2em;
  font-size: 14px;
  color: #666666;
  font-family: Microsoft YaHei;
  margin-bottom: 1em;
  visibility: visible;
  animation-duration: 1s;
  animation-delay: 0.25s;
  animation-iteration-count: 1;
  animation-name: bounceIn;
}

</style>
