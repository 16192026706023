<template>
  <div>
    <a-carousel arrows :autoplay="true" effect="fade" style="width: 100%;">
      <template #prevArrow>
        <div class="custom-slick-arrow" style="left: 10px; zindex: 1">
          <left-circle-outlined />
        </div>
      </template>
      <template #nextArrow>
        <div class="custom-slick-arrow" style="right: 10px">
          <right-circle-outlined />
        </div>
      </template>
      <div v-for="(item, i) in carouselList" :key="i" style="width: 100%" class="carousel-img-box">
        <img :src="item.img" :alt="item.title" />
      </div>
    </a-carousel>
    <!-- 药品信息（收费） -->
    <div class="page-section">
      <div class="container">
        <div class="content">
          <h3 class="page-title">药品信息（收费）</h3>
          <a-row class="production" type="flex" :gutter="[10, 10]">
            <a-col
              v-for="(item, i) in productionList"
              :key="i"
              :xs="12"
              :sm="12"
              :md="6"
              :lg="6"
              :xl="6"
            >
              <router-link :to="`${JumpToPageDetail}?id=${item.id}`">
                <production-item :info="item"></production-item>
              </router-link>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
    <!-- 公司简介 -->
    <company-profile></company-profile>
    <!-- 企业优势 -->
    <company-advantage></company-advantage>
  </div>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2021-11-24 14:03:02
 *@version: V1.0.0
 *@description: 首页
 *************************************************************************/
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons-vue";
import ProductionItem from "@/components/ProductionItem.vue"; //药品信息
import CompanyProfile from "@/components/CompanyProfile.vue";
import CompanyAdvantage from "@/components/CompanyAdvantage.vue";
export default {
  name: "",
  components: {
    LeftCircleOutlined,
    RightCircleOutlined,
    ProductionItem,
    CompanyProfile,
    CompanyAdvantage,
  },
  props: {},
  data() {
    return {
      carouselList: [
        {
          img: require("../assets/images/banner_a.jpg"),
          title: "",
        },
        {
          img: require("../assets/images/banner_b.jpg"),
          title: "",
        },
      ],
      // 产品信息
      productionList: [
        {
          id: 78,
          img: require("../assets/images/DUC001007F.png"),
          name: "妈咪爱",
          cDrugName: "枯草杆菌二联活菌颗粒",
        },
        {
          id: 79,
          img: require("../assets/images/ENC002002C.png"),
          name: "易坦静",
          cDrugName: "氨溴特罗口服溶液",
        },
        {
          id: 80,
          img: require("../assets/images/kcgjmca.jpg"),
          name: "美常安",
          cDrugName: "枯草杆菌二联活菌肠溶胶囊",
        },
        {
          id: 81,
          img: require("../assets/images/DAB013068D.png"),
          name: "润头孢克肟干混悬剂",
          cDrugName: "头孢克肟干混悬剂",
        },
        {
          id: 82,
          img: require("../assets/images/HQS016025G.png"),
          name: "润口腔炎喷雾剂",
          cDrugName: "口腔炎喷雾剂",
        },
        {
          id: 83,
          img: require("../assets/images/EHA002006C.png"),
          name: "仙达安",
          cDrugName: "盐酸西替利嗪口服溶液",
        },

        {
          id: 84,
          img: require("../assets/images/EHA004025C.png"),
          name: "仙达乐",
          cDrugName: "盐酸左西替利嗪口服溶液",
        },
        {
          id: 85,
          img: require("../assets/images/EOG001009C.png"),
          name: "利动",
          cDrugName: "乳果糖口服溶液",
        },
      ],
    };
  },
  computed: {
    JumpToPageDetail() {
      let rmkScssUserName = localStorage.getItem("rmkScssUserName");
      let path = "";
      if (rmkScssUserName) {
        path = "/production/detailInfo";
      } else {
        path = "/production/detail";
      }
      return path;
    },
  },
  watch: {},
  methods: {},
};
</script>
<style lang="less" scoped>
.ant-carousel :deep(.slick-slide) {
  text-align: center;
  overflow: hidden;
}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 30px;
  height: 30px;
  font-size: 30px;
  color: rgb(190, 190, 190);
  background-color: rgba(31, 45, 61, 0.11);
  border-radius: 100%;
  opacity: 0.3;
}
.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}
.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}
.carousel-img-box{
  img{
    width: 100%;
  }
}
</style>
